const moment = require('moment');

const PLANS = {
  solo: {
    id: 'solo',
    name: 'Solo',
    cost: 0,
    order: 1,
    maxAttendees: 100,
    maxMinutes: 120,
    recording: false,
    streaming: false,
    maxContentKit: 1,
  },
  free: {
    id: 'free',
    name: 'Free',
    cost: 0,
    order: 1,
    maxAttendees: 100,
    maxMinutes: 120,
    recording: false,
    streaming: false,
    maxContentKit: 6,
  },
  team: {
    id: 'team',
    name: 'Team',
    cost: 25,
    order: 2,
    maxAttendees: 50,
    maxMinutes: 120,
    recording: true,
    streaming: false,
  },
  event: {
    id: 'event',
    name: 'Event',
    cost: 200,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
  },
  starter: {
    id: 'starter',
    name: 'Starter',
    cost: 139,
    order: 3,
    maxAttendees: 100,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  starter_annual: {
    id: 'starter_annual',
    name: 'Starter Annual',
    cost: 1188,
    order: 3,
    maxAttendees: 100,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  business: {
    id: 'business',
    name: 'Business',
    cost: 599,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  business_annual: {
    id: 'business_annual',
    name: 'Business',
    cost: 499 * 12,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  podcast: {
    id: 'podcast',
    name: 'podcast',
    cost: 99,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  professional: {
    id: 'professional',
    name: 'Professional',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  enterprise: {
    id: 'enterprise',
    name: 'Enterprise',
    cost: 200,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  admin: {
    id: 'admin',
    name: 'Admin',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  pilot: {
    id: 'pilot',
    name: 'Pilot',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  trial: {
    id: 'trial',
    name: 'Trial',
    cost: 0,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
};

const APP_SUMO_PLANS = {
  1: {
    id: 1,
    name: 'App Sumo (Tier 1)',
    clipsPerMonth: 8,
    customDomain: false,
    additionalMembers: 0,
    watermark: true,
    linkedin: true,
    youtube: false,
  },
  2: {
    id: 2,
    name: 'App Sumo (Tier 2)',
    clipsPerMonth: 20,
    customDomain: false,
    additionalMembers: 2,
    watermark: false,
    linkedin: true,
    youtube: true,
  },
  3: {
    id: 3,
    name: 'App Sumo (Tier 3)',
    clipsPerMonth: null,
    customDomain: true,
    additionalMembers: null,
    watermark: false,
    linkedin: true,
    youtube: true,
  },
};

const planEnforcementTypes = {
  MEETING_FULL: 'MEETING_FULL',
  RECORDING_NOT_SUPPORTED: 'RECORDING_NOT_SUPPORTED',
  STREAMING_NOT_SUPPORTED: 'STREAMING_NOT_SUPPORTED',
  TIME_EXPIRING: 'TIME_EXPIRING',
};

const enforcementMessagesReceivers = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
  GUEST: 'GUEST',
};

const isValidTrial = (dateString) => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);
  const now = new Date();

  return moment(date).isAfter(moment(now));
};

const checkIsSubscriptionPastDue = (subscription) => {
  if (!subscription) {
    return false;
  }

  return subscription.status === 'past_due' || subscription.status === 'unpaid';
};

const checkIsCanceledBeforeBilling = (subscription) => {
  if (!subscription) {
    return false;
  }

  if (!subscription.cancel_at_period_end) {
    return true;
  }

  const isActive =
    subscription.status === 'active' || subscription.status === 'trialing';
  const isCanceled = !!subscription.canceled_at;
  const isInBilling = Date.now() < subscription.cancel_at * 1000; // Stripe returns unix timestamp, which is not in miliseconds

  return isActive && isCanceled && isInBilling;
};

const getTrialDateUntil = (workspace) => {
  if (!workspace) {
    return null;
  }

  return workspace.workspacePlanValidUntil;
};

const checkAppSumoFeature = (feature, plan) => {
  return APP_SUMO_PLANS?.[plan]?.[feature];
};

module.exports = {
  PLANS,
  APP_SUMO_PLANS,
  planEnforcementTypes,
  enforcementMessagesReceivers,
  getTrialDateUntil,
  checkIsSubscriptionPastDue,
  checkIsCanceledBeforeBilling,
  isValidTrial,
  checkAppSumoFeature,
};
