import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColorSelectInput } from '../../components/common/ColorSelectInput';
import { uploadBrandKitLogo } from '../../helper/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttachmentIcon } from '../../components/icons/AttachmentIcon';
import { fromError } from '../../helper/contextualLogger';
import { logerror } from '../../helper/contextualLogger';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { Button } from '../../components/common/Button';
import { ImageUploader } from '../../components/ImageUploader';
import { isHexColor } from '../../helper/isHexColor';
import {
  blankLogoUrl,
  fileNameWithoutWorkspaceIdAndDate,
} from '../../components/WorkspaceBrandKitForm';
import { getContrastColor } from 'zync-common/helper/color';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

const placeholderLogoUrl = getStaticAssetUrl('default_logo.png');

const LogoUploader = ({ logoUrl, handleChange, hideLogoPreview }) => {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmitLogo = useCallback(
    async (buffer, type, name) => {
      setIsSubmittingFile(true);

      try {
        const uploadResult = await uploadBrandKitLogo(
          'logo/' + name,
          buffer,
          type
        );
        if (uploadResult) {
          const newUrl =
            'https://storage.googleapis.com/zync-media/' +
            uploadResult?.result?.metadata?.name;

          handleChange('logoUrl')(newUrl);
        } else {
          setError('Error submitting logo, please try again.');
        }
      } catch (error) {
        logerror(fromError(error));
      }
      setIsSubmittingFile(false);
    },
    [handleChange]
  );

  const handleRemoveLogo = useCallback(() => {
    handleChange('logoUrl')(placeholderLogoUrl);
  }, [handleChange]);

  const onFileSizeExceeded = useCallback(() => {
    setFileSizeExceeded(true);
  }, []);

  const isPlaceholder =
    logoUrl === placeholderLogoUrl || logoUrl === blankLogoUrl;

  return (
    <div className="mt-2">
      {!isPlaceholder && logoUrl && (
        <div className="flex gap-2 mb-4 items-center">
          <div className="h-[35px] flex max-w-full min-w-0">
            {hideLogoPreview ? (
              <div className="flex gap-2 items-center max-w-full">
                <AttachmentIcon className="stroke-blue-gray" />
                <span className="text-blue-gray truncate">
                  {fileNameWithoutWorkspaceIdAndDate(logoUrl)}
                </span>
              </div>
            ) : (
              <img className="h-full w-auto" src={logoUrl} alt="" />
            )}
          </div>
          <div className="flex items-center gap-2">
            {logoUrl !== placeholderLogoUrl && logoUrl !== blankLogoUrl && (
              <button
                onClick={handleRemoveLogo}
                className="border border-red rounded-full w-5 h-5 flex items-center justify-center scale-80"
              >
                <span className="sr-only">
                  Click to remove the uploaded logo
                </span>
                <div className="shrink-0 w-5 h-5  flex items-center justify-center scale-75">
                  <FontAwesomeIcon icon="times" size="xs" color="#fa2a66" />
                </div>
              </button>
            )}
          </div>
        </div>
      )}
      {error && <div className="text-red text-xs font-light mb-2">{error}</div>}
      <div className="w-full" onClick={() => setFileSizeExceeded(false)}>
        <ImageUploader
          disabled={isSubmittingFile}
          loading={isSubmittingFile}
          label={
            <div className="flex gap-2 items-center justify-center">
              <AttachmentIcon className="stroke-white" />
              Upload Logo
            </div>
          }
          fileSizeBytes={2e6}
          onFileSizeExceeded={onFileSizeExceeded}
          buttonColor={Button.colors.PURPLE}
          onImageSubmit={handleSubmitLogo}
          appendTimeStampToFile={true}
          accept="image/png, image/gif, image/jpeg, image/jpg"
        />
        {fileSizeExceeded && (
          <p className="text-red text-xxs">
            Logo size exceeded. Try to upload a file that is less than 2
            megabytes (2MB).
          </p>
        )}
      </div>
    </div>
  );
};

const getInitialState = ({ brandKit }) => ({
  font: brandKit.font,
  primaryColor: brandKit.primaryColor,
  primaryContrast: brandKit.primaryContrast,
  accentColor: brandKit.accentColor,
  backgroundColor: brandKit.backgroundColor,
  logoUrl: brandKit.logoUrl,
  ctaText: brandKit.ctaText,
});

const checkIsColor = (strColor) => {
  return /^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3,4})$/.test(strColor);
};

export const WelcomeBrandKitForm = ({ workspace, onBrandKitChange }) => {
  const isInitialized = useRef(false);

  const [
    {
      primaryColor,
      primaryContrast,
      accentColor,
      backgroundColor,
      font,
      logoUrl,
      ctaText,
    },
    setBrandKitSettings,
  ] = useState(getInitialState(workspace));

  const userId = useSelector((st) => st.auth?.user?.userId);

  const handleChange = (key) => (value) => {
    setBrandKitSettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        [key]: value,
      };

      mixpanel.track('Onboarding: Updated Brand Kit', {
        distinct_id: userId,
        key,
        value,
      });

      return {
        ...newSettings,
        primaryContrast:
          key === 'primaryColor' && isHexColor(value)
            ? getContrastColor(value)
            : newSettings.primaryContrast,
      };
    });
  };

  useEffect(() => {
    if (!isInitialized.current) {
      return;
    }

    const newBrandkit = {
      brandKit: {
        primaryColor,
        primaryContrast,
        accentColor,
        backgroundColor,
        font,
        logoUrl,
        ctaText,
      },
    };

    onBrandKitChange && onBrandKitChange(newBrandkit);
  }, [
    onBrandKitChange,
    primaryColor,
    primaryContrast,
    accentColor,
    backgroundColor,
    font,
    logoUrl,
    ctaText,
  ]);

  useEffect(() => {
    isInitialized.current = true;
  }, []);

  const handleRestoreValue = (key, valueToRestore) => (event) => {
    const value = event.currentTarget.value;

    if (!checkIsColor(value)) {
      setBrandKitSettings((prevSettings) => ({
        ...prevSettings,
        [key]: valueToRestore,
      }));
    }
  };

  return (
    <div className="rounded-lg md:my-5 flex flex-col mb-5 w-fit max-w-full min-w-0 flex-1">
      <h4 className="text-blue-dark text-base font-semibold mb-4 md:block hidden">
        Your Brand Kit
      </h4>
      <div className="flex justify-between gap-4 flex-col flex-wrap">
        <ColorSelectInput
          label="Primary color"
          value={primaryColor}
          onChange={handleChange('primaryColor')}
          onBlur={handleRestoreValue(
            'primaryColor',
            workspace.brandKit.primaryColor
          )}
          width={120}
        />
        <ColorSelectInput
          label="Accent color"
          value={accentColor}
          onChange={handleChange('accentColor')}
          onBlur={handleRestoreValue(
            'accentColor',
            workspace.brandKit.accentColor
          )}
          width={120}
        />
        {/*<ColorSelectInput
          label="Background color"
          value={backgroundColor}
          onChange={handleChange('backgroundColor')}
          onBlur={handleRestoreValue(
            'backgroundColor',
            workspace.brandKit.backgroundColor
          )}
          width={120}
        />*/}
      </div>
      {/*<div className="mt-5">
        <TextInput
          label="Marketing Call to Action (limit to 50 characters)"
          value={ctaText || ''}
          onChange={(event) => handleChange('ctaText')(event.target.value)}
          maxLength={50}
        />
      </div>*/}
      <div className="mt-4">
        <label className="text-blue-dark text-sm font-medium">Logo</label>
        <LogoUploader
          logoUrl={logoUrl}
          handleChange={handleChange}
          hideLogoPreview
        />
      </div>
    </div>
  );
};
